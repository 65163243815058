/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "@fontsource/source-sans-pro"
import "@fontsource/rubik"
import Header from "./header"
import Footer from "./footer"
import { Global } from "@emotion/core"
//import "./layout.css"

const Layout = ({ children, headerType }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        maxWidth: "1600px",
        margin: "0 auto",
      }}
    >
      <Global
        styles={theme => ({
          body: {
            //   backgroundColor: "pink",
            ".visuallyhidden": {
              border: "0",
              clip: "rect(0 0 0 0)",
              height: "1px",
              margin: "-1px",
              overflow: "hidden",
              padding: "0",
              position: "absolute",
              width: "1px",
            },
            ".skipToContent": {
              zIndex: 5,
              color: "white",
              backgroundColor: "black",
              display: "inline-block",
              fontSize: "18px",
              position: "absolute",
              transform: "translateY(-100%)",
            },
            ".skipToContent:focus": {
              transform: "translateY(0%)",
            },
          },
        })}
      />
      <a href="#mainContent" className="skipToContent">
        Skip To Main Content
      </a>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        headerType={headerType}
      />
      {/* <div
      // style={{
      //   margin: `0 auto`,
      //   maxWidth: 960,
      //   padding: `0 1.0875rem 1.45rem`,
      // }}
      > */}
      <main sx={{ mb: 5, maxWidth: "100vw" }} id="mainContent">
        {children}
      </main>

      <Footer />
      {/* </div> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
